<template>
  <div>
    <div v-for="post in card.posts" :class="(card.animation_enabled) ? getAnimation(card.animation) : ''"
         :style="bioSectionThemeStyleBinding('background',getBioLinkAdds)" class="bio_card mb-4 pr-2 d-flex">
      <div class="text-center text-white">
        <div v-if="!post.link && !post.image" class="default_card pl-2 pr-2"><i class="fas fa-image"></i></div>
        <div v-else class="image" :style="{ backgroundImage: `url('${post.image}')` }"></div>
      </div>
      <div>
        <div class="d-block content p-2">
          <h6 :style="bioSectionThemeStyleBinding('text',getBioLinkAdds)">{{ post.title }}</h6>
          <p :style="bioSectionThemeStyleBinding('text',getBioLinkAdds)"><small>{{ post.description }}</small></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default ({
  props: [
    'isPreview',
    'card',
    'getBioLinkAdds'
  ],
  methods: {},
})
</script>
<style lang="less" scoped>

.bio_card {
  border-radius: 10px;
  background: whitesmoke;

  .default_card {
    background-color: lightgray;
    font-size: xxx-large;
    border-radius: 10px 0px 0px 10px;
  }

  .image {
    border-radius: 10px 0px 0px 10px;
    height: 100%;
    width: 80px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    h6 {
      font-size: small;
      font-weight: bold;
    }
  }
}
</style>
